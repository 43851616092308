<template>
  <div>
    <el-dialog
      ref="ruleForm"
      title="参与详情"
      :visible.sync="dialogShow"
      :close-on-click-modal="false"
      width="1200px"
      custom-class="cus_dialog"
      @close="closeDialog"
    >
      <el-table
        v-tableFit
        :data="tableData"
        row-key="id"
        height="100%"
        size="small"
        stripe
        border
        style="width: fit-content; width: 100%"
      >
        <el-table-column type="index" label="序号" width="65" align="center" />

        <el-table-column
          label="姓名"
          prop="stu_name"
          show-overflow-tooltip
          min-width="120"
          align="center"
        />

        <el-table-column label="学号" prop="stu_code" width="120" align="center">
          
        </el-table-column>
        <el-table-column label="班级" prop="class_name" width="180" align="center" />

        <el-table-column label="报名时间" prop="cdate" width="150" align="center">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "xjhjoinlist",
  components: {},
  props: ["xjh_id"],
  data() {
    return {
      dialogShow: true,
      DataList: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http
        .post("/api/zp_xjhjoin_list", { page: 1, pagesize: 2000, xjh_id: this.xjh_id })
        .then((res) => {
          this.DataList = res.data.data;
        });
    },
    closeDialog() {
      this.dialogShow = false;
      this.$$parent(this, "XJHID", false);
    },
  },
};
</script>

<style scoped lang="less"></style>
