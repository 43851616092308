<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">
      
        <el-input placeholder="标题关键字" v-model="keyword" size="small" class="input-with-select"
          style="width:150px;margin: 0 10px 10px 0;" clearable></el-input>
       
        <el-button icon="el-icon-search" style="margin: 0 10px 10px 0;" size="small" type="primary"
          @click="() => { page.current_page = 1; getList()}">搜索</el-button>
      </div>
      <div class="flexStart"
        style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">

        <el-button icon="el-icon-plus" style="margin: 0 10px 10px 0;" size="small" type="primary"
          @click="addNew">添加宣讲会</el-button>
      </div>

    </el-row>
    <div class="dividerBar"></div>

     <el-table v-tableFit :data="tableData" row-key="id" height="100%" size="small" stripe border
      style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="65" align="center" />
    
      <el-table-column label="标题名称" prop="title_main" show-overflow-tooltip min-width="180" align="center" />
   
     
      <el-table-column label="开始时间" prop="info" width="300" align="center">
        <template slot-scope="scope">
          {{scope.row.stime}}
        </template>
      </el-table-column>
      <el-table-column label="地址" prop="xjh_address" min-width="180" align="center" />
 


      <el-table-column label="报名人数" prop="join_count" width="100" align="center">
        <template slot-scope="scope">
          <el-tag type="" size="small" @click="viewStu(scope.row)">{{scope.row.join_count}}人</el-tag>
         
        </template>
      </el-table-column>

      <el-table-column label="状态" prop="info" width="100" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status=='未开始'" type="" size="small">{{scope.row.status}}</el-tag>
          <el-tag v-if="scope.row.status=='报名截止'" type="warning" size="small">{{scope.row.status}}</el-tag>
          <el-tag v-if="scope.row.status=='进行中'" type="success" size="small">{{scope.row.status}}</el-tag>
        </template>
      </el-table-column>
      
      
      


      <el-table-column fixed="right" label="操作" width="160" align="center">
        <template slot-scope="scope">
            <!-- 只有常州旅商有宣讲会 -->
          <el-tag class="pointer"   @click="goXuanjianghui(scope.row.id)" type="success"
            size="mini" style="margin-left:10px;">编辑</el-tag>

            <el-tag class="pointer"   @click="deleteXjh(scope.row.id)" type="warning"
            size="mini" style="margin-left:10px;">删除</el-tag>
            <el-tag class="pointer"   @click="viewXjh(scope.row.id)" type="info"
            size="mini" style="margin-left:10px;">预览</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>


    <qyxjhjoin v-if="XJHID" :xjh_id="XJHID"></qyxjhjoin>

    
  </div>
</template>
  
<script>
import qyxjhjoin from "./qyxjhjoin.vue"
export default {
  components:{
    qyxjhjoin
  },
  data() {
    return {
      keyword:"",
      tableData: [],
      dialogForm: false,
      formData: {},
      XJHID:0,

      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
     
    };
  },
 
  mounted() {
   
      this.getList();
    
  },
  methods: {
  
    getList() {
      let data = {};
      data.keyword= this.keyword
      data.page = this.page.current_page;
      data.pagesize = this.page.per_page;
      this.$http.post("/api/zp_xjh_list", data).then(res => {
        for(let item of res.data.data){
          let stime = new Date(item.stime.replace(/-/g,'/')).getTime()
          let etime = new Date(item.etime.replace(/-/g,'/')).getTime()
          if(stime>res.data.now){
            item.status="未开始"
          }else if(etime < res.data.now){
            item.status="报名截止"
          }else{
            item.status="进行中"
          }
        }
        this.tableData = res.data.data;
        this.page = res.data.page;
      });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
   
    deleteXjh(st) {
     
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        

        this.$http
          .post("/api/zp_xjh_delete", {
            id: st
          })
          .then(res => {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
            this.getList();
          
          });
      });
    },
    viewXjh(id){
      window.open("/zhaopin/#/qyxjh/" + id);
    },
    exportExcelDate() {
      let data = {};
      data.login_name = this.searchForm.login_name;
      data.firm_name = this.searchForm.firm_name;
      data.firm_num = this.searchForm.firm_num;
      data.tel = this.searchForm.tel;
      data.info = this.searchForm.info;
      data.email_status = this.searchForm.email_status;
      data.is_cooperation = this.searchForm.is_cooperation;
      data.check_status = this.searchForm.check_status;
      data.qyly = 1;
      data.page = this.page.current_page;
      this.$http.post("/api/export_enterprise_list", data).then(res => {
        if (res.data.url) {
          window.open(res.data.url);
        }
      });
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_zp_enter", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    },
    addNew(){
      this.$router.push("/xjhinfo?id=0")
    },
    goXuanjianghui(id){
      
      this.$router.push("/xjhinfo?id="+id)
    },
    viewStu(row){
      this.XJHID = row.id
      console.log(this.XJHID)
    }
  }
};
</script>
  
<style scoped>
/deep/ .el-form-item {
  margin-bottom: 0px;
}
</style>